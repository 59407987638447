import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Theme from '../../Theme';
import * as Ant from 'antd';
import {Download} from '@styled-icons/evaicons-solid/Download';
import {getCdnUrl} from '../../Utils/CdnUtil';

const pageSize = 20;
const collectionName = 'us_magazine';

function InfoMagazines(props) {
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQuery = {};
      const resp = await JStorage.fetchDocuments(
        collectionName,
        extraQuery,
        ['-date'],
        paging,
      );

      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [paging]);

  React.useEffect(() => {
    async function fetchData() {
      fetchDocumentList();
    }

    fetchData();
  }, [fetchDocumentList]);

  return (
    <Wrapper>
      <h1 style={{fontSize: 28}}>美國專利電子報</h1>

      <Ant.Table
        columns={[
          {
            title: '書名',
            key: 'title',
            render: (_, record) => {
              return (
                <div>
                  <div style={{color: '#888'}}>{record.date}</div>
                  <h3>{record.title}</h3>
                </div>
              );
            },
          },
          {
            title: '下載',
            key: 'file',
            render: (_, record) => {
              if (!record.file) {
                return null;
              }

              return (
                <Ant.Button
                  type="link"
                  target={'_blank'}
                  href={getCdnUrl(record.file)}>
                  <Download size={24} />
                </Ant.Button>
              );
            },
          },
        ]}
        dataSource={fetchResp?.results}
        rowKey={'id'}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['bottomCenter'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          },
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }
`;

export default withPageEntry(InfoMagazines);
